<template>
  <div>
    <div class="container-fluid text-left">
      <h1 class="px20">
        Ergebnisse <span v-if="q">für "{{q}}"</span>
      </h1>

      <div class="header">
        <h2>
          Filme & Serien
        </h2>
      </div>
      <div class="movies-list-small" v-if="movies">
        <div v-for="entry in movies" :key="entry.slug" class="entry">
            <router-link :to="{name: 'Product', params: {slug: entry.slug}}">
              <img v-lazy="{ src: entry.image.file }" :alt="entry.title" />
            </router-link>
            <div>
              <h3>
                <router-link :to="{name: 'Product', params: {slug: entry.slug}}">
                  {{ entry.title }}
                </router-link>
              </h3>
              <p>{{ entry.year }}</p>
            </div>
          <div class="clear"></div>
        </div>
      </div>
      <p v-else class="px20 grey">Keine Ergebnisse</p>
      <div class="clear"></div>

      <div class="header">
        <h2>
          Nachrichten & Features
          <nav class="submenu">
            <router-link :to="{name: 'Newslist'}"> Alle Nachrichten </router-link>
            <router-link :to="{name: 'Features'}"> Alle Features </router-link>
          </nav>
        </h2>
      </div>
      <div class="news" v-if="news">
        <div v-for="news_item in news" :key="news_item.slug" class="entry">
            <h3>
              <router-link :to="{name: 'News', params: {slug: news_item.slug}, force: true}">
                {{ news_item.title }}
              </router-link>
            </h3>
            <p><span class="tag">{{ date_format(news_item.publication_date) }}</span></p>
            <p class="hide-xs">{{ news_item.description }}</p>
        </div>
        <div class="clear"></div>
      </div>
      <p v-else class="px20 grey">Keine Ergebnisse</p>

      <div class="clear"></div>
      <div class="header">
        <h2>
          Personen
        </h2>
      </div>
      <div v-if="persons">
        <p class="px20" v-for="entry in persons" :key="entry.slug">
          <router-link :to="{name: 'Person', params: {slug: entry.slug}}">
            {{ entry.forname }} {{ entry.surname }}
          </router-link>
        </p>
      </div>
      <p v-else class="px20 grey">Keine Ergebnisse</p>
    </div>
  </div>
</template>
<script>
import { dater } from '@/modules/utilities.js'
export default {
  data () {
    return {
      movies: '',
      news: '',
      persons: '',
      q: ''
    }
  },
  methods: {
    date_format (date) {
      return dater(date)
    },
    fetchData () {
      fetch('https://api.kino-total.net/search/?q=' + this.$route.params.q, {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.movies = data.results.products
            this.news = data.results.news
            this.persons = data.results.persons
            this.q = data.query
            document.querySelector('head meta[name="description"]').setAttribute('content', 'Suche nach Themen, Kritiken und Artikeln auf Kino-Total.net')
            document.querySelector('head title').textContent = 'Kino-Total.net - Suche'
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://stats.kino-total.net/search/')
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
