<template>
  <div v-if="news.translations && news.listview">
    <router-link :to="{name: 'News', params: {slug: news.slug}}">
      <img v-if="news.listview" class="listview" v-lazy="{ src: news.listview[0].file }" :alt="news.translations[0].headline" />
    </router-link>
    <h3>
      <router-link :to="{name: 'News', params: {slug: news.slug}}">
        {{ news.translations[0].headline }}
      </router-link>
    </h3>
    <span v-if="news.publication_date" class="tag">{{ date_format(news.publication_date) }}</span>
    <span v-if="news.genres" class="genres">
      <span v-for="news_entry in news.genres" :key="news_entry.slug">
        <router-link class="tag" :to="{name: 'News', params: {slug: news.slug}}">
          {{news_entry.title}}
        </router-link>
      </span>
    </span>
    <p class="hide-xs">{{ news.translations[0].description }}</p>
  </div>

</template>
<script>
import { dater } from '@/modules/utilities.js'
export default {
  name: 'NewsEntry',
  props: {
    news: {}
  },
  methods: {
    date_format (date) {
      return dater(date)
    }
  }
}
</script>
