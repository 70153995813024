import { createRouter, createWebHistory } from 'vue-router'
import Homepage from '../views/HomepageView.vue'
import Impressum from '../views/ImpressumView.vue'
import Movies from '../views/MoviesView.vue'
import Product from '../views/ProductView.vue'
import Series from '../views/SeriesView.vue'
import Streamings from '../views/StreamingsView.vue'
import Newslist from '../views/NewslistView.vue'
import News from '../views/NewsView.vue'
import Features from '../views/FeaturesView.vue'
import BoxOffices from '../views/BoxOfficesView.vue'
import Person from '../views/PersonView.vue'
import Authors from '../views/AuthorsView.vue'
import Author from '../views/AuthorView.vue'
import AuthorNews from '../views/AuthorNewsView.vue'
import AuthorProducts from '../views/AuthorProductsView.vue'
import Genre from '../views/GenreView.vue'
import Classics from '../views/ClassicsView.vue'
import Search from '../views/SearchView.vue'

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: Homepage
  },
  { path: '/news-feature/', redirect: to => { return { path: '/news/' } } },
  { path: '/2022/', redirect: to => { return { path: '/movies/' } } },
  { path: '/2023/', redirect: to => { return { path: '/movies/' } } },
  { path: '/2024/', redirect: to => { return { path: '/movies/' } } },
  { path: '/autoren/', redirect: to => { return { path: '/authors/' } } },
  { path: '/klassiker/', redirect: to => { return { path: '/classics/' } } },
  { path: '/category-news/', redirect: to => { return { path: '/news/' } } },
  { path: '/category-features/', redirect: to => { return { path: '/features/' } } },
  { path: '/category/box-office/', redirect: to => { return { path: '/box-office/' } } },
  { path: '/category-box-office/', redirect: to => { return { path: '/box-office/' } } },
  { path: '/movie-genre/kritiken/', redirect: to => { return { path: '/movies/' } } },
  { path: '/movie-genre/serie/', redirect: to => { return { path: '/series/' } } },
  { path: '/streaming-kritiken/', redirect: to => { return { path: '/streamings/' } } },
  { path: '/person/:slug/', redirect: to => { return { path: '/persons/' + to.params.slug } } },
  { path: '/movie/kritik-zu-:slug/', redirect: to => { return { path: '/' + to.params.slug } } },
  { path: '/:year(\\d+)/:month(\\d+)/:day(\\d+)/:slug/', redirect: to => { return { path: '/news-feature/' + to.params.slug } } },
  {
    path: '/movies/',
    name: 'Movies',
    component: Movies
  },
  {
    path: '/movies/:page/',
    name: 'MoviesPage',
    component: Movies
  },
  {
    path: '/classics/',
    name: 'Classics',
    component: Classics
  },
  {
    path: '/classics/:page/',
    name: 'ClassicsPage',
    component: Classics
  },
  {
    path: '/series/',
    name: 'Series',
    component: Series
  },
  {
    path: '/series/:page/',
    name: 'SeriesPage',
    component: Series
  },
  {
    path: '/streamings/',
    name: 'Streamings',
    component: Streamings
  },
  {
    path: '/streamings/:page/',
    name: 'StreamingsPage',
    component: Streamings
  },
  {
    path: '/news/',
    name: 'Newslist',
    component: Newslist
  },
  {
    path: '/news/:page',
    name: 'NewslistPage',
    component: Newslist
  },
  {
    path: '/features/',
    name: 'Features',
    component: Features
  },
  {
    path: '/features/:page/',
    name: 'FeaturesPage',
    component: Features
  },
  {
    path: '/box-office/',
    name: 'BoxOffices',
    component: BoxOffices
  },
  {
    path: '/box-office/:page',
    name: 'BoxOfficesPage',
    component: BoxOffices
  },
  {
    path: '/news-feature/:slug',
    name: 'News',
    component: News
  },
  {
    path: '/persons/:slug',
    name: 'Person',
    component: Person
  },
  {
    path: '/authors/',
    name: 'Authors',
    component: Authors
  },
  {
    path: '/authors/:username/',
    name: 'Author',
    component: Author
  },
  {
    path: '/authors/:username/news/',
    name: 'AuthorNews',
    component: AuthorNews
  },
  {
    path: '/authors/:username/news/:page/',
    name: 'AuthorNewsPage',
    component: AuthorNews
  },
  {
    path: '/authors/:username/reviews/',
    name: 'AuthorProducts',
    component: AuthorProducts
  },
  {
    path: '/authors/:username/reviews/:page/',
    name: 'AuthorProductsPage',
    component: AuthorProducts
  },
  {
    path: '/impressum/',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/genre/:slug/',
    name: 'Genres',
    component: Genre
  },
  {
    path: '/genre/:slug/:page/',
    name: 'GenresPage',
    component: Genre
  },
  {
    path: '/:slug/',
    name: 'Product',
    component: Product
  },
  {
    path: '/datenschutzerklaerung/',
    name: 'Datenschutz',
    component: () => import(/* webpackChunkName: "Datenschutz" */ '../views/DatenschutzView.vue')
  },
  {
    path: '/verified/',
    name: 'Verifziert',
    component: () => import(/* webpackChunkName: "Datenschutz" */ '../views/VerifiedView.vue')
  },
  {
    path: '/search/:q/',
    name: 'Search',
    component: Search
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    window.scrollTo(0, 0)
  }
})

export default router
