<template>
  <div id="app" class="container">
    <header>
      <div class="container-fluid">
        <h1 id="logo">
          <router-link to="/">
            <img src="@/assets/Logo.svg" alt="LOGO">
            KINO-TOTAL.net
          </router-link>
        </h1>
        <nav id="menu">
          <router-link class="exact" to="/">Home</router-link>
          <router-link class="fuzzy" :to="{name: 'Movies'}">Filme<span class="badge">1 Neu</span></router-link>
          <router-link class="fuzzy" :to="{name: 'Series'}">Serien</router-link>
          <router-link class="fuzzy" :to="{name: 'Streamings'}">Streaming</router-link>
          <router-link class="fuzzy" :to="{name: 'Classics'}">Klassiker</router-link>
          <router-link class="fuzzy" :to="{name: 'Newslist'}">News</router-link>
          <router-link class="fuzzy" :to="{name: 'Features'}">Features</router-link>
          <router-link class="fuzzy" :to="{name: 'BoxOffices'}">Boxoffice</router-link>
        </nav>
        <div class="search">
          <input type="text" v-model="input" placeholder="Suche..." @input="search" />
          <div v-if="active_search" class="results">
            <div class="item result" v-for="entry in results" :key="entry.slug">
              <router-link @click="clearInput()" v-if="entry.type === 'Product'" :to="{name: 'Product', params: {slug: entry.slug}}">Film: {{ entry.title }}</router-link>
              <router-link @click="clearInput()" v-if="entry.type === 'News'" :to="{name: 'News', params: {slug: entry.slug}}">News: {{ entry.title }}</router-link>
              <router-link @click="clearInput()" v-if="entry.type === 'Person'" :to="{name: 'Person', params: {slug: entry.slug}}">Person: {{ entry.forname }} {{ entry.surname }}</router-link>
            </div>
            <div class="item error" v-if="active_search && !results.length">
               <p>Keine Ergebnisse</p>
            </div>
          </div>
        </div>
        <nav id="mobile-menu" role="navigation">
          <div id="menuToggle">
            <input ref="menuToggleInput" type="checkbox" aria-label="Mobiles Menü"/>
            <span></span>
            <span></span>
            <span></span>
            <ul id="mobile" v-on:click="closeMenu">
              <li><router-link class="exact" to="/">Home</router-link></li>
              <li><router-link class="fuzzy" :to="{name: 'Movies'}">Filme</router-link></li>
              <li><router-link class="fuzzy" :to="{name: 'Series'}">Serien</router-link></li>
              <li><router-link class="fuzzy" :to="{name: 'Streamings'}">Streaming</router-link></li>
              <li><router-link class="fuzzy" :to="{name: 'Classics'}">Klassiker</router-link></li>
              <li><router-link class="fuzzy" :to="{name: 'Newslist'}">News</router-link></li>
              <li><router-link class="fuzzy" :to="{name: 'Features'}">Features</router-link></li>
              <li><router-link class="fuzzy" :to="{name: 'BoxOffices'}">Boxoffice</router-link></li>
            </ul>
          </div>
        </nav>
      </div>
    </header>

    <div id="main">
      <router-view/>
    </div>

    <footer>
      <div class="container-fluid">
        <div class="footer-left">
          <router-link to="/" class="logo">KINO-TOTAL.NET</router-link>
          <p>
            <router-link :to="{name: 'Impressum'}">Impressum</router-link>
            | <router-link :to="{name: 'Datenschutz'}">Datenschutz</router-link>
            | <router-link :to="{name: 'Authors'}">Autoren</router-link>
            | <span class="cookies" v-on:click="$CookieConsent.showPreferences()">Cookies</span>
          </p>
        </div>
        <div class="footer-center">
          <div class="newsletter newsletter-in" ref="newsletter-submit">
            <b>Nichts verpassen!</b>
            <p>Melden Dich zum Newsletter an (oder ab):</p>
            <input ref="email" type="email" id="email" placeholder="E-Mail-Adresse">
            <button @click="createNewSubscriber('in')">Anmelden</button>
          </div>
          <div class="newsletter newsletter-out" ref="newsletter-out-submit">
            <b>Achtung!</b>
            <p>Möchtest du dich wirklich abmelden?</p>
            <input ref="email-out" type="email" id="email_out" placeholder="E-Mail-Adresse">
            <button @click="createNewSubscriber('out')">Abmelden</button>
          </div>
        </div>
        <div class="footer-right">
          Unsere sozialen Kanäle:
          <ul>
            <li v-if="settings['youtube'] && settings['youtube'].html"><a target="_blank" :href="settings['youtube'].html">YouTube</a></li>
            <li v-if="settings['x'] && settings['x'].html"><a target="_blank" :href="settings['x'].html">X</a></li>
            <li v-if="settings['letterboxed'] && settings['letterboxed'].html"><a target="_blank" :href="settings['letterboxed'].html">Letterboxed</a></li>
            <li v-if="settings['facebook'] && settings['facebook'].html"><a target="_blank" :href="settings['facebook'].html">Facebook</a></li>
          </ul>
        </div>
      </div>
    </footer>
    <div class="scroll-container">
      <a href="#">∧</a>
    </div>
  </div>
</template>

<script>
import { bootstrap } from 'vue-gtag'

export default {
  name: 'App',
  head () {
    return {
      meta: [{
        hid: 'og:locale', property: 'og:locale', content: this.currentLocaleDecoding
      }],
      htmlAttrs: {
        lang: this.currentLocale ? this.currentLocale : 'de'
      }
    }
  },
  data () {
    return {
      settings: '',
      onLine: navigator.onLine,
      q: false,
      results: false,
      input: '',
      active_search: false,
      csrf: false
    }
  },
  methods: {
    clearInput () {
      this.input = ''
      this.results = false
      this.active_search = false
    },
    updateCookieConsent (e) {
      if (e.detail.cookie.services.analytics[0] === 'ga') {
        bootstrap().then(() => {})
        this.$gtag.optIn()
      } else {
        this.$gtag.optOut()
      }
    },
    scrollTop () {
      document.getElementById('app').scrollIntoView()
    },
    updateOnlineStatus (e) {
      const { type } = e
      this.onLine = type === 'online'
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    closeMenu () {
      this.$refs.menuToggleInput.checked = false
    },
    fetchData () {
      fetch('https://api.kino-total.net/website/', {
        method: 'GET', credentials: 'include'
      })
        .then((response) => {
          response.json().then((data) => {
            this.settings = data.settings
            document.cookie = response.headers['Set-Cookie']
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },
    search () {
      if (this.input.length > 3) {
        fetch('https://api.kino-total.net/quicksearch/?q=' + this.input, {
          method: 'GET'
        })
          .then((response) => {
            response.json().then((data) => {
              this.results = data.results
              this.q = data.query
              this.active_search = true
            })
          })
          .catch((err) => {
            console.error(err)
            this.active_search = false
          })
      } else {
        this.active_search = false
      }
    },
    createNewSubscriber (inout = 'in') {
      const value = document.cookie.match('(^|;)?csrftoken=([^;]*)(;|$)')
      this.csrf = value ? value[2] : null
      console.log(this.csrf)
      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'X-CSRFTOKEN': this.csrf }
      }
      fetch('https://api.kino-total.net/newsletter/?inout=' + inout + '&email=' + this.$refs.email.value, requestOptions)
        .then((response) => {
          response.json().then((data) => {
            if (data.status === 'error') {
              if (data.message === 'exists') {
                this.$refs['newsletter-out-submit'].style.display = 'block'
                this.$refs['newsletter-submit'].style.display = 'none'
                this.$refs['email-out'].value = this.$refs.email.value
              } else {
                this.$refs['newsletter-out-submit'].style.display = 'none'
                this.$refs['newsletter-submit'].style.display = 'block'
                this.$refs['newsletter-submit'].innerHTML = '<b>Es ist ein Fehler aufgetreten!</b><p>Bitte versuche es später noch einmal.</p>'
              }
            } else {
              if (data.message === 'deleted') {
                this.$refs['newsletter-out-submit'].style.display = 'none'
                this.$refs['newsletter-submit'].style.display = 'block'
                this.$refs['newsletter-submit'].innerHTML = '<b>Erfolgreich ausgetragen!</b><p>Wenn du willst, kannst du dich jederzeit auf dieser Seite wieder neu eintragen.</p>'
              } else {
                this.$refs['newsletter-submit'].innerHTML = '<b>Vielen Dank!</b><p>Wir haben Dir eine E-Mail geschickt, um Deine Anmeldung zu bestätigen.</p>'
              }
            }
          })
        })
        .catch((err) => {
          console.error(err)
        })
    }
  },
  async created () {
    this.fetchData()
  },
  mounted () {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
    window.addEventListener('cc:onChange', this.updateCookieConsent)
    window.addEventListener('cc:onConsent', this.updateCookieConsent)
  },
  beforeUnmount () {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
    window.removeEventListener('cc:onChange', this.updateCookieConsent)
    window.addEventListener('cc:onConsent', this.updateCookieConsent)
  },
  currentLocale () {
    return this.$i18n.locale
  }
}
</script>
