<template>
  <div>
    <div class="container-fluid">
      <div class="person-detail">

        <div class="person-main">

          <div class="person-content">
            <h2>
              {{person.forname}} {{person.surname}}
            </h2>
            <p v-if="person.birth_date">Geburtstag: {{person.birth_date}}</p>
            <p v-if="person.translations && person.translations[0].description">
              <b>
                {{person.translations[0].description}}
              </b>
            </p>
          </div>

          <div class="header" v-if="person.news">
            <h2>
              Nachrichten & Features
            </h2>
          </div>
          <div class="movies-list-small" v-if="person.news">
            <div v-for="entry in person.news" :key="entry.slug" class="entry">

                <router-link :to="{name: 'News', params: {slug: entry.slug}}">
                  <img v-if="entry.image" :src="entry.image" :alt="entry.title" />
                </router-link>
                <div>
                  <h5>
                    <router-link :to="{name: 'News', params: {slug: entry.slug}}">
                      {{ entry.title }}
                    </router-link>
                  </h5>
                  <p><span class="tag">{{ date_format(entry.publication_date) }}</span></p>
                </div>
              <div class="clear"></div>
            </div>
          </div>

          <div class="clear"></div>

          <div class="header" v-if="person.products">
            <h2>
              Filme & Serien
            </h2>
          </div>
          <div class="movies-list-small" v-if="person.products">
            <div v-for="entry in person.products" :key="entry.slug" class="entry">
                <router-link :to="{name: 'Product', params: {slug: entry.slug}}">
                  <img v-if="entry.image" :src="entry.image.file" :alt="entry.title" />
                </router-link>
                <div>
                  <h5>
                    <router-link :to="{name: 'Product', params: {slug: entry.slug}}">
                      {{ entry.title }}
                    </router-link>
                  </h5>
                  <p v-if="entry.role">Rolle: {{entry.role}}</p>
                  <p>{{ entry.year }}</p>
                </div>
              <div class="clear"></div>
            </div>
          </div>
          <div class="clear"></div>
        </div>

        <aside class="person-aside">
          <img v-if="person.images" class="poster" :src="person.images[0].file" :alt="person.surname" />
        </aside>

        <div class="clear"></div>

      </div>
    </div>
  </div>
</template>
<script>
import { dater } from '@/modules/utilities.js'
export default {
  data () {
    return {
      person: ''
    }
  },
  methods: {
    date_format (date) {
      return dater(date)
    },
    fetchData () {
      fetch('https://api.kino-total.net/persons/' + this.$route.params.slug + '/', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.person = data.person
            if (this.person) {
              document.querySelector('head meta[name="description"]').setAttribute('content', 'Artikel zu Filmen und Serien mit ' + this.person.forname + ' ' + this.person.surname)
              document.querySelector('head title').textContent = 'Kino-Total.net - Person: ' + this.person.forname + ' ' + this.person.surname
            }
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://stats.kino-total.net/persons/' + this.$route.params.slug)
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
<style>
.person-detail {
  display: block;
  text-align: left;
}
.person-content {
  padding-left: 20px;
}
.person-main {
  width: 70%;
  float: left;
  padding-right: 20px;
}
.person-aside {
  width: 30%;
  float: left;
  margin-top: 40px;
}
</style>
