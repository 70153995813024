<template>
  <router-link :to="{name: 'Product', params: {slug: movie.slug}}">
    <img v-if="movie.poster" class="poster" v-lazy="{ src: movie.poster[0].file }" :alt="movie.translations[0].title" />
    <div v-else class="no-image-poster"></div>
  </router-link>
  <div v-if="movie.fsk" class="hide fsk-container"><span class="fsk">{{movie.fsk}}</span></div>
  <div v-if="movie.review && movie.review[0].style" class="stars" :style="movie.review[0].style"></div>
  <h2>
    <router-link :to="{name: 'Product', params: {slug: movie.slug}}">{{ movie.translations[0].title }}</router-link>
  </h2>
  <p v-if="movie.actors">Mit {{movie.actors.map(entry => (entry.forname + " " +entry.surname)).join(', ')}} </p>

</template>
<script>
export default {
  name: 'MovieEntry',
  props: {
    movie: {}
  }
}
</script>
