<template>
  <div>
    <div class="container-fluid">
      <div class="header">
        <h1>
          Impressum
        </h1>
      </div>
      <p class="text-left pre-formatted px20 lh15" v-html="impressum" />
    </div>
  </div>
</template>

<script>
import { markdown } from '@/modules/utilities.js'
export default {
  name: 'App',
  data () {
    return {
      impressum: ''
    }
  },
  methods: {
    markdown_format (data) {
      return markdown(data)
    },
    fetchData () {
      fetch('https://api.kino-total.net/website/', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            if (data.settings.impressum && data.settings.impressum.html) {
              this.impressum = this.markdown_format(data.settings.impressum.html)
              document.querySelector('head meta[name="description"]').setAttribute('content', 'Informationen über die Webseite Kino-Total.net')
              document.querySelector('head title').textContent = 'Kino-Total.net - Impressum'
            }
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://stats.kino-total.net/impressum/')
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
