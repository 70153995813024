<script setup>
import NewsEntry from '../components/NewsEntry.vue'
</script>
<template>
  <div>
    <div class="container-fluid">
      <nav class="breadcrumb">
        <router-link to="/">Home</router-link> / <router-link to="/authors">Autoren</router-link> / <router-link :to="{name: 'Author', params: {username: person.username}}">{{person.forname}} {{person.surname}}</router-link> / Nachrichten
      </nav>

      <h1 class="text-left px20">
        Kritiken von {{person.forname}} {{person.surname}}
      </h1>
      <div class="news" v-if="news">
        <div v-for="news_item in news" :key="news_item[1].slug" class="entry">
          <NewsEntry :news="news_item[1]"/>
        </div>
        <div class="clear"></div>
      </div>
      <div v-else class="loader">Wird geladen ...</div>

      <div class="pagination" v-if="news">
        <div class="pagination-left">
          <router-link class="button" v-if="(offset - 12) > 0" :to="{name: 'AuthorNewsPage', params: {username: username, page: Math.max(0, Math.abs(offset - 12))}, force: true}">Vorherige Seite</router-link>
          <router-link class="button" v-else-if="(offset - 12) === 0" :to="{name: 'AuthorNews', params: {username: username}, force: true}">Vorherige Seite</router-link>
          <button v-else disabled>Vorherige Seite</button>
        </div>
        <div class="pagination-right">
          <router-link class="button" v-if="(offset + 12) < count" :to="{name: 'AuthorNewsPage', params: {username: username, page: Math.abs(offset + 12)}, force: true}">Nächste Seite</router-link>
          <button v-else disabled>Nächste Seite</button>
        </div>
        <div class="clear"></div>
      </div>

    </div>
  </div>
</template>
<script>
import { dater } from '@/modules/utilities.js'
const fetchUrl = 'https://api.kino-total.net/news/'
const limit = '12'
export default {
  data () {
    return {
      news: '',
      count: 0,
      offset: 0,
      person: '',
      username: ''
    }
  },
  methods: {
    date_format (date) {
      return dater(date)
    },
    fetchData (username, page) {
      this.username = username
      fetch('https://api.kino-total.net/authors/' + username + '/', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.person = data.author
            if (this.person) {
              document.querySelector('head meta[name="description"]').setAttribute('content', 'Alle Nachrichten und Features von ' + this.person.forname + ' ' + this.person.surname)
              document.querySelector('head title').textContent = 'Kino-Total.net - Nachrichten und Features von Autor ' + this.person.forname + ' ' + this.person.surname
            }
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch(fetchUrl + '?author=' + username + '&limit=' + limit + (page > 0 ? '&offset=' + page : ''), {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.news = data.news
            this.count = data.count
            this.offset = data.offset
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://stats.kino-total.net/authors/news/' + username + '/')
    }
  },
  created () {
    this.fetchData(this.$route.params.username, this.$route.params.page)
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.news = null
        this.fetchData(toParams.username, toParams.page)
        window.scrollTo(0, 0)
      }
    )
  }
}
</script>
