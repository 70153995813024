<template>
  <div>
    <div class="container-fluid">

      <div class="header">
        <h1>
          Autoren
        </h1>
      </div>

      <div class="px20">
        <div v-if="persons" class="avatars">
          <div class="pb20" v-for="entry in persons" :key="entry.username">
            <router-link :to="{name: 'Author', params: {username: entry.username}}">
              <img :src="entry.gravatar" alt="avatar1" />
            </router-link>
            <h3><router-link :to="{name: 'Author', params: {username: entry.username}}">
              {{entry.first_name}} {{entry.last_name}}
            </router-link></h3>
            <i>Autor</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      persons: ''
    }
  },
  methods: {
    fetchData () {
      fetch('https://api.kino-total.net/authors/', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.persons = data.authors
            document.querySelector('head meta[name="description"]').setAttribute('content', 'Eine Übersicht unser kritischen Redakteure')
            document.querySelector('head title').textContent = 'Kino-Total.net - Autoren'
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://stats.kino-total.net/authors/')
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
<style>

</style>
