<template>
  <div>
    <div class="container-fluid">
      <nav class="breadcrumb">
        <router-link to="/">Home</router-link> / <router-link to="/authors">Autoren</router-link> / {{person.forname}} {{person.surname}}
      </nav>
      <div class="person-detail" v-if="person">

        <div class="person-main">

          <div class="person-content">
            <h1>
              {{person.forname}} {{person.surname}}
            </h1>
            <date v-if="person.birth_date">Geburtstag: {{person.birth_date}}</date>
            <p v-if="person.description">
              {{person.description}}
            </p>
          </div>
        </div>
        <aside class="person-aside">
          <img v-if="person.gravatar" :src="person.gravatar" :alt="person.surname" />
        </aside>
      </div>

      <div class="clear"></div>

      <div class="header" v-if="person.news">
        <h2>
          Aktuelle Nachrichten & Features verfasst von {{person.forname}} {{person.surname}}
        </h2>
      </div>
      <div class="movies-list-small" v-if="person.news">
        <div v-for="entry in person.news" :key="entry.slug" class="entry">

            <router-link :to="{name: 'News', params: {slug: entry.slug}}">
              <img v-if="entry.image" :src="entry.image" :alt="entry.title" />
            </router-link>
            <div>
              <h5>
                <router-link :to="{name: 'News', params: {slug: entry.slug}}">
                  {{ entry.title }}
                </router-link>
              </h5>
              <span class="tag">{{ date_format(entry.publication_date) }}</span>
            </div>
          <div class="clear"></div>

        </div>
        <div class="pagination" v-if="person.news">
          <div class="pagination-right">
              <router-link class="button" :to="{name: 'AuthorNews', params: {username: person.username}}">Weitere News</router-link>
          </div>
        </div>
      </div>

      <div class="clear"></div>

      <div class="header" v-if="person.products">
        <h2>
          Aktuelle Kritiken von {{person.forname}} {{person.surname}}
        </h2>
      </div>
      <div class="movies" v-if="person.products">
        <div v-for="movie in person.products" :key="movie.slug" class="entry">

          <router-link :to="{name: 'Product', params: {slug: movie.slug}}">
            <img v-if="movie.image" class="poster" v-lazy="{ src: movie.image.file }" :alt="movie.title" />
            <div v-else class="no-image-poster"></div>
          </router-link>
          <div v-if="movie.style" class="stars" :style="movie.style"></div>
          <h2>
            <router-link :to="{name: 'Product', params: {slug: movie.slug}}">{{ movie.title }}</router-link>
          </h2>
        </div>
        <div class="clear"></div>
      </div>
      <div class="pagination" v-if="person.products">
        <div class="pagination-right">
          <router-link class="button" :to="{name: 'AuthorProducts', params: {username: person.username}}">Weitere Kritiken</router-link>
        </div>
      </div>
      <div class="clear"></div>
      <p></p>
    </div>

    <div class="clear"></div>

  </div>
</template>
<script>
import { dater } from '@/modules/utilities.js'
export default {
  data () {
    return {
      person: ''
    }
  },
  methods: {
    date_format (date) {
      return dater(date)
    },
    fetchData () {
      fetch('https://api.kino-total.net/authors/' + this.$route.params.username + '/', {
        method: 'GET'
      })
        .then((response) => {
          response.json().then((data) => {
            this.person = data.author
            if (this.person) {
              document.querySelector('head meta[name="description"]').setAttribute('content', 'Alle Artikel von ' + this.person.forname + ' ' + this.person.surname)
              document.querySelector('head title').textContent = 'Kino-Total.net - Autor: ' + this.person.forname + ' ' + this.person.surname
            }
          })
        })
        .catch((err) => {
          console.error(err)
        })
      fetch('https://stats.kino-total.net/authors/' + this.$route.params.username + '/')
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
<style>
.person-detail {
  display: block;
  text-align: left;
}
.person-content {
  padding-left: 20px;
}
.person-main {
  width: 70%;
  float: left;
  padding-right: 20px;
}
.person-aside {
  width: 30%;
  float: left;
  margin-top: 25px;
}
</style>
